<template>
  <div class="scroll-wrapper">
    <div class="pullup">
      <div class="pullup-wrapper" ref="scrollRef">
        <div class="pullup-content">
          <div class="pullup-list">
            <slot></slot>
          </div>
          <!-- <div class="pullup-tips">
            <div v-if="!isPullUpLoad" class="before-trigger">
              <span class="pullup-txt">Pull up and load more</span>
            </div>
            <div v-else class="after-trigger">
              <span class="pullup-txt">Loading...</span>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- End of the body -->
  </div>
</template>
<script>
import { ref, onMounted, nextTick, onUnmounted } from 'vue';
import BScroll from '@better-scroll/core/dist/core';
import Pullup from '@better-scroll/pull-up/dist/pull-up';
BScroll.use(Pullup);

export default {
  components: {},
  emits: ['pulling-up'],
  setup(_, { emit }) {
    const scrollRef = ref(null);
    let bscroll;

    const pullingUp = () => {
      console.log('[bs]pullingUpHandler...');
      emit('pulling-up', null);
      bscroll.finishPullUp();
    };

    onMounted(() => {
      bscroll = new BScroll(scrollRef.value, {
        click: true,
        pullUpLoad: true,
      });
      bscroll.on('pullingUp', pullingUp);
    });

    const refresh = () => {
      nextTick(() => bscroll.refresh());
    };

    const isPullUpLoad = ref(false);

    onUnmounted(() => {
      bscroll.destroy();
    });

    return { scrollRef, isPullUpLoad, refresh };
  },
};
</script>
<style lang="scss" scoped>
.scroll-wrapper {
  overflow: hidden;
  
  .pullup {
    height: 100%;

    .pullup-wrapper {
      height: 100%;
      overflow: hidden;

      .pullup-list {
        padding: 0;
      }

      .pullup-tips {
        padding: 20px;
        color: #999;
        text-align: center;
      }
    }
  }
}
</style>
